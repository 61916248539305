import clsx from "clsx";
import Loading from "components/Loading";

const InlineLoading = ({ align, message = "Loading..." }) => {
  const className = clsx("InlineLoading", {
    "InlineLoading--alignLeft": align === "left",
  });

  return (
    <div className={className}>
      <div className="InlineLoading__loading">
        <Loading color="#DEDEDE" size={25} />
      </div>
      <div className="InlineLoading__label">{message}</div>
    </div>
  );
};

InlineLoading.defaultProps = {
  align: "center",
};

export default InlineLoading;
