/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from "clsx";
import Checkbox from "components/Checkbox";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";

const CategoriesCheckboxes = observer(
  ({
    categories,
    initialSelectedCategories,
    onChange,
    isScrollable,
    showNoneOption,
    darkMode = false,
  }) => {
    const [selectedCategories, setSelectedCategories] = useState(
      initialSelectedCategories
    );

    // When the selected categories change, call the callback
    useEffect(() => {
      onChange(selectedCategories);
    }, [selectedCategories, onChange]);

    const selectCategory = (category) => {
      setSelectedCategories((prevState) => [...prevState, category]);
    };

    const deselectCategory = (category) => {
      setSelectedCategories((prevState) =>
        prevState.filter(
          (c) => parseInt(c.id, 10) !== parseInt(category.id, 10)
        )
      );
    };

    const handleChange = (category) => {
      if (
        selectedCategories.find(
          (c) => parseInt(c.id, 10) === parseInt(category.id, 10)
        )
      ) {
        deselectCategory(category);
      } else {
        selectCategory(category);
      }
    };

    const className = clsx("CategoriesCheckboxes", {
      "CategoriesCheckboxes--isScrollable": isScrollable,
    });

    return (
      <div className={className}>
        <div className="CategoriesCheckboxes__categories">
          {showNoneOption && (
            <div className="CategoriesCheckboxes__category">
              <label className="CategoriesCheckboxes__label items-center">
                <div className="CategoriesCheckboxes__checkbox">
                  <Checkbox
                    id="none"
                    checked={selectedCategories.length === 0}
                    rounded
                  />
                </div>
                <div className="CategoriesCheckboxes__labelLabel">None</div>
              </label>
            </div>
          )}
          {categories.map((category) => {
            const checked = !!selectedCategories.find(
              (c) => parseInt(c.id, 10) === parseInt(category.id, 10)
            );

            return (
              <Category
                key={category.id}
                category={category}
                onChange={handleChange}
                checked={checked}
                darkMode={darkMode}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

const Category = observer(
  ({ category, onChange, checked, darkMode = false }) => {
    const handleChange = () => {
      onChange(category);
    };

    return (
      <div className="CategoriesCheckboxes__category flex">
        <label className="CategoriesCheckboxes__label items-center">
          <div className="CategoriesCheckboxes__checkbox">
            <Checkbox
              id={category.id}
              onChange={handleChange}
              checked={checked}
              darkMode={darkMode}
              rounded
            />
          </div>
          <div className="CategoriesCheckboxes__labelLabel">
            {category.title}
          </div>
        </label>
      </div>
    );
  }
);

CategoriesCheckboxes.defaultProps = {
  categories: [],
  initialSelectedCategories: [],
  onChange: () => null,
  isScrollable: false,
  showNoneOption: false,
};

export default CategoriesCheckboxes;
