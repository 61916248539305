import Button from "@/components/Button";
import Loading from "@/components/Loading";
import CategoriesByGroup from "@/containers/CategoriesByGroup";
import { useStores } from "@/hooks/useStores";
import { showNotification } from "@/lib/helpers";
import clsx from "clsx";
import CategoriesCheckboxes from "components/CategoriesCheckboxes";
import { observer } from "mobx-react";
import React, { useState } from "react";

const DietaryRequirements = ({ darkMode = false, onSave = () => null }) => {
  const [isSaving, setIsSaving] = useState(false);

  const { authStore } = useStores();
  const { currentUser } = authStore;

  const handleSaveClick = () => {
    setIsSaving(true);

    const accountStatus = authStore.currentUser.save();

    accountStatus
      .then((response) => {
        if (response.data.error) {
          showNotification(
            `There was a problem updating your preferences: ${response.data.error}`
          );
          return Promise.reject(response.data.error);
        }
      })
      .finally(() => {
        setIsSaving(false);
        showNotification(`Saved`);
        onSave();
      });
  };

  const _Button = () => {
    return (
      <div className={clsx("pt-6")}>
        <Button
          label={isSaving ? "Saving..." : "Save"}
          theme="dark"
          isFull
          onClick={handleSaveClick}
        />
      </div>
    );
  };

  return (
    <div>
      <h3 className={clsx("heading-xs")}>Dietary requirements</h3>
      <p className={clsx("mb-6 mt-2 font-body text-sm")}>
        Select your dietary requirements to personalise your experience
      </p>
      <div>
        <CategoriesByGroup
          groupId={3}
          loadingComponent={<Loading color="#ffffff" />}
          render={(categories) => {
            return (
              <>
                <CategoriesCheckboxes
                  categories={categories}
                  initialSelectedCategories={currentUser.dietaryRequirements}
                  onChange={currentUser.setDietaryRequirements}
                  darkMode={darkMode}
                />
                <div className="w-full">
                  <_Button />
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

DietaryRequirements.displayName = "DietaryRequirements";

export default observer(DietaryRequirements);
