const ErrorMessage = ({ error }) => {
  if (!error) return null;

  return (
    <div className="ErrorMessage">
      <div className="ErrorMessage__content">
        An error occurred: {error.toString()}
      </div>
    </div>
  );
};

ErrorMessage.defaultProps = {
  error: null,
};

export default ErrorMessage;
