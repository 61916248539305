import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import introspectionQueryResultData from "fragmentTypes.json";

const baseUrl = process.env.NEXT_PUBLIC_CMS_URL + "/graphql";

export default function createApolloClient(initialState, ctx) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    // create a new fragment matcher
    introspectionQueryResultData,
  });

  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: new HttpLink({
      uri: `${baseUrl}`, // Server URL (must be absolute)
      credentials: "include",
      headers: {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_GRAPHQL_TOKEN}`,
      },
      fetch,
    }),
    cache: new InMemoryCache({ fragmentMatcher }).restore(initialState),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
}
